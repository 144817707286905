import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import { ChangeLocale, GlobalSettingsContext, ContextLocale } from './context';
import { useRouter } from 'next/router';
import { Locale } from '@mws/wordengine-graph';

const LocaleToggler: FunctionComponent = () => {
    const router = useRouter();

    const { state, dispatch } = useContext(GlobalSettingsContext);
    const { locale } = state;

    return (
        <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }} size="small">
            <InputLabel>Locale</InputLabel>
            <Select
                value={locale}
                label="Locale"
                onChange={(event) => {
                    const newLocale = event.target.value as Locale;
                    router.query = { ...router.query, locale: newLocale };
                    router
                        .replace(router)
                        .then(() => dispatch(ChangeLocale(event.target.value as Locale)));
                }}>
                {Object.values(ContextLocale).map((name) => (
                    <MenuItem key={name} value={name}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default LocaleToggler;
